export enum Role {
  All = "All",
  Admin = "Admin",
  Doctor = "Doctor",
  Patient = "Patient",
  Administrator = "Administrator",
  SuperAdministrator = "SuperAdministrator",
  BranchUser = "Branch User",
  Teacher = "Teacher",
  Student = "Student",
}
